import { firebase } from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
const firebaseConfig = {
  apiKey: "AIzaSyCXa_KZ1ZeYixkNzTZLkCQGubr7nQKX9O0",
  authDomain: "bestill-app.firebaseapp.com",
  databaseURL: "https://bestill-app.firebaseio.com",
  projectId: "bestill-app",
  storageBucket: "bestill-app.appspot.com",
  messagingSenderId: "634311419885",
  appId: "1:634311419885:web:8af952bbc00c3ce1d729e4",
  measurementId: "G-1QDCFNRDD8"
}; 
// const firebaseConfig = {
//   apiKey: "AIzaSyDJV0amdI-ox5hbqv6TUIgDV_bn3fKkUgU",
//   authDomain: "be-still-v2.firebaseapp.com",
//   projectId: "be-still-v2",
//   storageBucket: "be-still-v2.appspot.com",
//   messagingSenderId: "699798003228",
//   appId: "1:699798003228:web:21f73b346435a0b40cc9d9",
//   measurementId: "G-L1K1ENP5EG"
// };
firebase.initializeApp(firebaseConfig);
export default firebase; 