import React, { Component } from 'react';
import Spinner from 'react-spinkit';
import  firebase from "../reference.js";
import {isMobile, isIOS, isAndroid} from 'react-device-detect';

export default class ResetPassword extends Component {
  state = {
    email: null,
    error: '',
    password: '',
    success: false,
    validCode: null,
    verifiedCode: false,
    authRef: firebase.auth(),
  }

  componentDidMount() {
    // Verify the password reset code is valid.
    this.state.authRef
      .verifyPasswordResetCode(this.props.actionCode)
      .then(email => {
        this.setState({ email, validCode: true, verifiedCode: true });
      }, error => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        this.setState({ error: error.message, validCode: false, verifiedCode: true });
      });
  }

  validate(password) {
    // event.preventDefault();

    // const { password } = this.state;
    const re = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$");
    const isOk = re.test(password);

    if(!isOk) {
      return document.getElementById('validation-failed').innerHTML = '<p>Password must be at least 6 characters and contain minimum of 1 Uppercase, 1 Lowercase and 1 Digit</p>';    
  }
    document.getElementById('validation-failed').innerHTML = '<p></p>';
}

  handleResetPassword = (event) => {
    event.preventDefault();
    const { actionCode } = this.props;
    const newPassword = this.state.password;
    const re = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$");
    const isOk = re.test(this.state.password);
    if(!isOk) return;
    // Save the new password.
    this.state.authRef
      .confirmPasswordReset(actionCode, newPassword)
      .then(() => {
        // Password reset has been confirmed and new password updated.
        this.setState({ success: true });
      }, error => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
        this.setState({ error: error.message });
      });
  }

  setText = (evt) => {
    this.setState({ password: evt.target.value });
    this.validate(evt.target.value);
  }

  
  backToApp = () => {
    // if(isIOS) {
    //   window.open('secondBestill://');
    // } else if(isAndroid){
    //   window.open('org.second.bestill');
    // }
    
    window.open('https://bestilltest.page.link/welcom');
    // window.open('https://bestillapp.page.link/welcome');
    
  };

  render() {
    const {
      email,
      error,
      password,
      success,
      validCode,
      verifiedCode,
    } = this.state;

    let component;
    if (!verifiedCode) {
      component = <Spinner name="three-bounce" />;
    } else if (success) {
      component = (
        <div className="ResetPassword">
          <h1 className="text-center">Password changed</h1>
          <p className="text-center">You can now sign in with your new password.</p>
          
        {isMobile?  <button onClick={this.backToApp}>
             RETURN TO BE STILL APP
            </button>:''}
        </div>
      );
    } else if (verifiedCode && validCode) {
      component = (
        <div className="ResetPassword">
          <h1 className="text-center">Reset your password</h1>
          <div className="text-center">for <span>{email}</span></div>
          <div className="space-y"></div>
          <form onSubmit={this.handleResetPassword}>

            {error ? <div className="error">{error}</div> : ''}

            <input
              onChange={this.setText}
              value={password}
              type="password"
              placeholder="New password"
              required
            />
            <input
              type="submit"
              value="SAVE"
            />
          </form>
          <div id="validation-failed" className="error"></div>
        </div>
      );
    } else if (verifiedCode && !validCode) {
      component = (
        <div className="ResetPassword">
          <h1 className="text-center">Try resetting your password again</h1>
          <p className="error text-center">The code is invalid or has expired.</p>
        </div>
      );
    }

    return component;
  }
}
